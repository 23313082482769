import { i18n, features } from 'commerce.config.js'

import { getThemeLocale } from '@lib/utils/tools'

export const BRAND = process.env.NEXT_PUBLIC_BRAND || 'anker'

export const COMMERCE_FEATURES = features

export const SHOPIFY_COOKIE_EXPIRE = 30

export const USE_DEFAULT_LOGIN = process.env.NEXT_PUBLIC_USE_DEFAULT_LOGIN

export const MULPASS_URL =
  process.env.NEXT_PUBLIC_ENV === 'production'
    ? `https://passport.${BRAND}.com`
    : `https://beta.passport.${BRAND}.com`

export const API_TOKEN = process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN

export const STORE_DOMAIN = process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN

export const API_URL = `https://${STORE_DOMAIN}/api/2022-07/graphql.json`

export const I18N_API_TOKEN =
  process.env.NEXT_PUBLIC_I18N_SHOPIFY_STOREFRONT_TOKEN &&
  JSON.parse(process.env.NEXT_PUBLIC_I18N_SHOPIFY_STOREFRONT_TOKEN)

export const I18N_STORE_DOMAIN =
  process.env.NEXT_PUBLIC_I18N_SHOPIFY_STORE_DOMAIN &&
  JSON.parse(process.env.NEXT_PUBLIC_I18N_SHOPIFY_STORE_DOMAIN)

export const FAKE_PRICE = 9999999.99

export const I18N_THEME_DOMAIN =
  I18N_STORE_DOMAIN &&
  (function () {
    const { locales = [] } = i18n
    const result: { [key: string]: string } = {}
    locales.forEach((locale) => {
      result[locale] =
        process.env.NEXT_PUBLIC_ENV === 'production' &&
        locale !== 'my' &&
        locale !== 'vn'
          ? `${getThemeLocale(locale)}.${BRAND}.com`
          : I18N_STORE_DOMAIN[locale]
    })
    return result
  })()

export const I18N_API_URL =
  process.env.NEXT_PUBLIC_ENV === 'production'
    ? I18N_THEME_DOMAIN &&
      (function () {
        const result: { [key: string]: string } = {}
        Object.keys(I18N_THEME_DOMAIN).forEach((locale) => {
          result[
            locale
          ] = `https://${I18N_THEME_DOMAIN[locale]}/api/2022-07/graphql.json`
        })
        return result
      })()
    : I18N_STORE_DOMAIN &&
      (function () {
        const result: { [key: string]: string } = {}
        Object.keys(I18N_STORE_DOMAIN).forEach((locale) => {
          result[
            locale
          ] = `https://${I18N_STORE_DOMAIN[locale]}/api/2022-07/graphql.json`
        })
        return result
      })()

export const SHOPIFY_CART_COOKIE = (function () {
  const { locales = [] } = i18n
  const result: { [key: string]: string } = {}
  locales.forEach((locale) => {
    result[locale] = `shopifyCart_${I18N_STORE_DOMAIN[locale]}`
  })
  return result
})()

export const SHOPIFY_CHECKOUT_ID_COOKIE = (function () {
  const { locales = [] } = i18n
  const result: { [key: string]: string } = {}
  locales.forEach((locale) => {
    result[locale] = `shopifyCheckoutId_${I18N_STORE_DOMAIN[locale]}`
  })
  return result
})()

export const SHOPIFY_CHECKOUT_URL_COOKIE = (function () {
  const { locales = [] } = i18n
  const result: { [key: string]: string } = {}
  locales.forEach((locale) => {
    result[locale] = `shopifyCheckoutUrl_${I18N_STORE_DOMAIN[locale]}`
  })
  return result
})()

export const SHOPIFY_CUSTOMER_TOKEN_COOKIE = (function () {
  const { locales = [] } = i18n
  const result: { [key: string]: string } = {}
  locales.forEach((locale) => {
    result[locale] = `shopifyCustomerToken_${I18N_STORE_DOMAIN[locale]}`
  })
  return result
})()

export const ORIGIN_URL = {
  anker:
    process.env.NEXT_PUBLIC_ENV === 'production'
      ? 'https://www.anker.com'
      : 'https://beta.anker.com',
}[BRAND]

export const ANKER_COUNTRY_SUFFIX: {
  [key: string]: string
} = {
  us: 'Anker US',
  uk: 'Anker UK',
  ca: 'Anker Canada',
  'eu-en': 'Anker Europe',
  'eu-de': 'Anker Deutschland',
  fr: 'Anker FR',
  au: 'Anker AU',
}

export const EUFY_COUNTRY_SUFFIX: {
  [key: string]: string
} = {
  us: 'Eufy US',
  uk: 'Eufy UK',
  ca: 'Eufy Canada',
  'eu-en': 'Eufy Europe',
  'eu-de': 'Eufy Deutschland',
}

export const ANKERMAKE_COUNTRY_SUFFIX: {
  [key: string]: string
} = {
  us: 'Ankermake US',
  uk: 'Ankermake UK',
  ca: 'Ankermake Canada',
  'eu-en': 'Ankermake Europe',
  'eu-de': 'Ankermake Deutschland',
}
