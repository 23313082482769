import productFragment from './product'

const cartFragment = /* GraphQL */ `
  fragment cart on Cart {
    id
    totalQuantity
    updatedAt
    createdAt
    checkoutUrl
    note
    attributes {
      key
      value
    }
    cost {
      checkoutChargeAmount {
        amount
        currencyCode
      }
      subtotalAmount {
        amount
        currencyCode
      }
      totalAmount {
        amount
        currencyCode
      }
      totalTaxAmount {
        amount
        currencyCode
      }
      totalDutyAmount {
        amount
        currencyCode
      }
      totalAmountEstimated
      totalTaxAmountEstimated
      totalDutyAmountEstimated
      subtotalAmountEstimated
    }
    buyerIdentity {
      email
      customer {
        email
        id
      }
      deliveryAddressPreferences {
        ... on MailingAddress {
          address1
          address2
          city
          country
          zip
        }
      }
    }
    discountCodes {
      applicable
      code
    }
    discountAllocations {
      ... on CartCodeDiscountAllocation {
        code
        discountedAmount {
          amount
          currencyCode
        }
      }
      ... on CartAutomaticDiscountAllocation {
        title
        discountedAmount {
          amount
          currencyCode
        }
      }
      ... on CartCustomDiscountAllocation {
        title
        discountedAmount {
          amount
          currencyCode
        }
      }
    }
    deliveryGroups(first: 10) {
      edges {
        node {
          deliveryAddress {
            address1
            address2
            city
            country
            zip
          }
        }
      }
    }
    lines(first: 100) {
      edges {
        node {
          id
          quantity
          discountAllocations {
            ... on CartCodeDiscountAllocation {
              code
              discountedAmount {
                amount
                currencyCode
              }
            }
            ... on CartAutomaticDiscountAllocation {
              title
              discountedAmount {
                amount
                currencyCode
              }
            }
            ... on CartCustomDiscountAllocation {
              title
              discountedAmount {
                amount
                currencyCode
              }
            }
          }
          attributes {
            key
            value
          }
          cost {
            amountPerQuantity {
              amount
              currencyCode
            }
            compareAtAmountPerQuantity {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalAmount {
              amount
              currencyCode
            }
          }
          merchandise {
            ... on ProductVariant {
              id
              sku
              title
              quantityAvailable
              currentlyNotInStock
              availableForSale
              barcode
              selectedOptions {
                name
                value
              }
              image {
                originalSrc
                altText
                width
                height
              }
              priceV2 {
                amount
                currencyCode
              }
              compareAtPriceV2 {
                amount
                currencyCode
              }
              product {
                ...product
              }
            }
          }
        }
      }
    }
    totalQuantity
  }
  ${productFragment}
`

export default cartFragment
