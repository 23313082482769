import Cookies from 'js-cookie'
import { Cart as ShopifyCart } from 'lib/shopify/gql'
import { normalizeCart } from 'lib/utils/normalize'

import { SHOPIFY_CART_COOKIE } from 'lib/config/const'

// todo!
import { atobID } from '@lib/utils/tools'

const syncCart = (cartData: ShopifyCart, locale: string) => {
  const cart = normalizeCart(cartData)
  const shopifyCartCookie = SHOPIFY_CART_COOKIE[locale || 'us']
  const hostUrl = window.location.host.split('.')

  Cookies.set(
    shopifyCartCookie,
    JSON.stringify({
      from: 'shopifyStorefront',
      type: 'storefontCart',
      items: cart.lineItems.map((item) => ({
        id: atobID(item.variantId),
        quantity: item.quantity,
      })),
    }),
    {
      domain:
        hostUrl.length > 2
          ? `.${hostUrl[1]}.${hostUrl[2]}`
          : window.location.hostname,
      expires: 14,
    }
  )
}

export default syncCart
