export const checkoutDetailsFragment = /* GraphQL */ `
  fragment checkoutDetails on Checkout {
    id
    webUrl
    completedAt
    createdAt
  }
`

export const getCheckoutQuery = /* GraphQL */ `
  query getCheckout($checkoutId: ID!) {
    node(id: $checkoutId) {
      ...checkoutDetails
    }
  }
  ${checkoutDetailsFragment}
`

export const checkoutCreateMutation = /* GraphQL */ `
  mutation checkoutCreate(
    $lineItems: [CheckoutLineItemInput!]
    $customAttributes: [AttributeInput!]
  ) {
    checkoutCreate(
      input: { lineItems: $lineItems, customAttributes: $customAttributes }
    ) {
      checkout {
        id
        webUrl
      }
      checkoutUserErrors {
        field
        message
      }
    }
  }
`

export const discountCodesUpdateCheckoutMutation = /* GraphQL */ `
  mutation checkoutDiscountCodeApplyV2(
    $checkoutId: ID!
    $discountCode: String!
  ) {
    checkoutDiscountCodeApplyV2(
      checkoutId: $checkoutId
      discountCode: $discountCode
    ) {
      checkout {
        id
        webUrl
      }
      checkoutUserErrors {
        field
        message
      }
    }
  }
`
